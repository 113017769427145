import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide1.module.css"
// import { gsap } from "gsap/dist/gsap";
import H1 from "./slide1h"
import Svg from '../../assets/s1_4.svg';
import Svgm from '../../assets/s1_4m.svg';
import { withpath } from '../../utils/index.js'
const txt = [
	"This alone should start prompting multipreneurs to think about their own plans, perhaps initiate some tough conversations, and look to protect their businesses while also investing in a better future for generations to come."
]

const Slide1_1 = (p) => {

	return (
		<section className={ss.slide} data-id={"1_4"}>
			
			<div 
id={`slidebg1_4`} 
			className={ss.slidebg} data-id={"1_4"} style={{backgroundImage: `url(${withpath(`/img/slide14.jpg`)})` }} />

			<div className={ss.h1}>
				<H1 />
			</div>
			<div className={ss.txttxt} data-id={"1_4"}>
				<div className={ss.svg} data-id={"1_4"}>
					<div className={ss.mob}>
						<Svgm />
					</div>
					<div className={ss.desc}>
						<Svg />
					</div>
				</div>
				<div 
					className={ss.simpletxt} 
					data-id={"1_4"} 
					dangerouslySetInnerHTML={{__html: txt[0] }} 
					data-swiper-parallax="-400"
				/>
			</div>
		</section>
)}

export default Slide1_1