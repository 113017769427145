import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide2.module.css"
// import { gsap } from "gsap/dist/gsap";
import { withpath } from '../../utils/index.js'
import H1 from "./slide2h"

const txt = [
	"As well as being big investors, most borderless multipreneurs are active business leaders and originators, always looking for the next project or big thing to get excited about. Many are advising or leading global teams, yet also tend to have good instincts when it comes to spotting entrepreneurial potential and, after one or two successes, they can be willing to back ideas – their own and others’ – with increasing confidence and astute judgement.",
	"This often leads to interests in multiple entrepreneurial endeavours, with varying levels of active involvement",
	"across their portfolio.",
	"How can you juggle a number of projects without dropping the ball?"
]

const Slide2_0 = (p) => {

	return (
		<section className={ss.slide} data-id={"2_0"}>
			<div id={`slidebg2_0`} className={ss.slidebg} data-id={"2_0"} style={{backgroundImage: `url(${withpath(`/img/slide20.jpg`)})` }} />
			<div className={ss.h1}>
				<H1 />
			</div>
			<div className={ss.txttxt} data-id={"2_0"}>
				<div className={ss.txtwhite} dangerouslySetInnerHTML={{__html: txt[0] }}  data-swiper-parallax="400"/>
				<div className={ss.txtblackbg} dangerouslySetInnerHTML={{__html: txt[1] }}  data-swiper-parallax="200"/>
				<div className={ss.conturtxt1} data-swiper-parallax-scale="0.25">
					<span id="as20" className={ss.conturtxt1span} dangerouslySetInnerHTML={{__html: txt[2] }} />
				</div>
				<div className={ss.txtwhite2} dangerouslySetInnerHTML={{__html: txt[3] }} data-swiper-parallax="-400"/>
			</div>
		</section>
)}

export default Slide2_0