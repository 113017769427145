import React from 'react'
import * as ss from "./slide.module.css"
import * as s from "./slide0.module.css"
import { gsap } from "gsap/dist/gsap";
import H1 from "./slide0h"
import { withpath } from '../../utils/index.js'
import { Waypoint } from 'react-waypoint';

// import { withBreakpoints } from 'gatsby-plugin-breakpoints';

const txt = [
	"Diversified,",
	" borderless",
	" and",
	" curious",
	"We know that today’s multipreneur is many things: an inspiring business leader, an insightful creator, a hypermobile global resident, a diversified asset holder and a legacy leaver. They operate across time zones and borders, always thinking about new projects or global business opportunities, and driven by curiosity and a desire for progress, rather than simply money.",
	"In a world that has changed beyond recognition over the past two years, what does this mean for the rising number of multipreneurs when it comes to investment, lifestyle and legacy decisions?",
	"Explore the trends and behaviours that will impact and alter the entrepreneurial landscape over the next generation."
]

const Slide0_0 = (p) => {

	function animate(){
		let h0_0 = document.querySelector(`#h0_0`)
		let h0_1 = document.querySelector(`#h0_1`)
		let h0_l = document.querySelector(`#h0_l`)
		let sh = document.querySelectorAll(`.` + s.txth + ` > span`);
		let txt = document.querySelectorAll(`.` + s.txtp);
		let arrow = document.querySelector(`#mainarrow`);

		gsap.killTweensOf([h0_0, h0_1, h0_l, sh, txt, arrow]);

		gsap.set(h0_0, {opacity: 0, x: 30})
		gsap.set(h0_1, {opacity: 0})
		gsap.set(h0_l, {opacity: 0, scaleX: 0})
		gsap.set(sh, {opacity: 0, y: 10})
		gsap.set(arrow, {opacity: 0})
		gsap.set(txt, {opacity: 0})

		let tl = gsap.timeline({ paused: false });
			tl.to(h0_0, {opacity: 1, x: 0, duration: 1, ease: `power1.out` }, 0.0);
			tl.to(h0_1, {opacity: 1, duration: 1, ease: `power1.out` }, 0.0);
			tl.to(h0_l, {opacity: 1, scaleX: 1, duration: 1.0, ease: `power1.out` }, 0.0);
			tl.to(sh, {opacity: 1, y: 0, duration: 1.0, ease: `power2.out`, stagger: 0.2 }, 0.5);
			tl.to(txt, {opacity: 1, duration: 1.5, ease: `power1.out`, stagger: 0.2 }, 1.0);
			tl.to(arrow, {opacity: 1, duration: 1.0, ease: `power1.out` }, 1.3);
	}

	function hide(){
		let h0_0 = document.querySelector(`#h0_0`)
		let h0_1 = document.querySelector(`#h0_1`)
		let h0_l = document.querySelector(`#h0_l`)
		let sh = document.querySelectorAll(`.` + s.txth + ` > span`);
		let txt = document.querySelectorAll(`.` + s.txtp);
		let arrow = document.querySelector(`#mainarrow`);

		gsap.killTweensOf([h0_0, h0_1, h0_l, sh, txt, arrow]);

		gsap.set(h0_0, {opacity: 0, x: 30})
		gsap.set(h0_1, {opacity: 0})
		gsap.set(h0_l, {opacity: 0, scaleX: 0})
		gsap.set(sh, {opacity: 0, y: 10})
		gsap.set(txt, {opacity: 0})
		gsap.set(arrow, {opacity: 0})
	}

	function enter(props){
		animate();
	}
	function leave(props){
		hide();
	}

	return (
		<Waypoint 
			onEnter={(p) => {enter(p)}} 
			onLeave={(p) => {leave(p)}} 
			bottomOffset={"0%"} 
			scrollableAncestor={`window`} 
			fireOnRapidScroll={false}
		>
			<section className={ss.slide} data-id={"0_0"}>

				<div className={ss.slidebg + ` ` + ss.mob} data-id={"0_0"} style={{backgroundImage: `url(${withpath(`/img/slide00m.jpg`)})` }} /> 
				<div className={ss.slidebg + ` ` + ss.desc} data-id={"0_0"} style={{backgroundImage: `url(${withpath(`/img/slide00.jpg`)})` }} /> 

				<div className={s.startbox}>

					<div className={s.leftbox}>
						<div className={s.h11}>
							<H1 />
						</div>
					</div>

					<div className={s.rightbox}>
						<div className={s.txth}>
							<span dangerouslySetInnerHTML={{__html: txt[0] }} />
							<span dangerouslySetInnerHTML={{__html: txt[1] }} />
							<span dangerouslySetInnerHTML={{__html: txt[2] }} />
							<span dangerouslySetInnerHTML={{__html: txt[3] }} />
						</div>
						<div className={s.txttxt}>
							<div id={`st_0`} className={s.txtp} dangerouslySetInnerHTML={{__html: txt[4] }} />
							<div id={`st_1`} className={s.txtp} dangerouslySetInnerHTML={{__html: txt[5] }} />
							<div id={`st_2`} className={s.txtp} dangerouslySetInnerHTML={{__html: txt[6] }} />
						</div>
					</div>
				</div>
			</section>
		</Waypoint>
)}

export default Slide0_0;