import React from 'react'
import * as s from "./slide4.module.css"

const txt = [
	"the diversified",
	"ASSET HOLDER",
]

const Slide4h = (p) => {

	return (
		<div className={s.h}>
			<div className={s.h_0 + ` h0`} dangerouslySetInnerHTML={{__html: txt[0] }} data-id={p.dataid}/>
			<div className={s.h_1 + ` h1`} dangerouslySetInnerHTML={{__html: txt[1] }} />
			<div className={s.h_line + ` hl`} />
		</div>
)}

export default Slide4h