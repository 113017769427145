// extracted by mini-css-extract-plugin
export const desc = "slide-module--desc--1qZiC";
export const mob = "slide-module--mob--1UItL";
export const slide = "slide-module--slide--2Bogo";
export const simpletxt = "slide-module--simpletxt--3wPOc";
export const fbox = "slide-module--fbox--38u9n";
export const txt = "slide-module--txt--2UQJa";
export const txt2 = "slide-module--txt2--52Yz-";
export const txtwhite = "slide-module--txtwhite--1ZXjd";
export const txtwhite2 = "slide-module--txtwhite2--27Aer";
export const txtblackbg = "slide-module--txtblackbg--2GWWA";
export const txtblackbg2 = "slide-module--txtblackbg2--1EeCs";
export const conturtxt = "slide-module--conturtxt--9ojNm";
export const conturspan10 = "slide-module--conturspan10--2X3Cd";
export const conturtxt1 = "slide-module--conturtxt1--1YXxw";
export const conturtxt2 = "slide-module--conturtxt2--2ciBc";
export const conturtxt1span = "slide-module--conturtxt1span--3xDRS";
export const midtxt = "slide-module--midtxt--2XHnv";
export const whiteblacktxt = "slide-module--whiteblacktxt--Yy39C";
export const h1 = "slide-module--h1--AN4TK";
export const quotetxt = "slide-module--quotetxt--3bvTw";
export const quotetxtred = "slide-module--quotetxtred--1ZAs4";
export const quotetxtblack = "slide-module--quotetxtblack--P_qkl";
export const quoteautor = "slide-module--quoteautor--uFFms";
export const quotetxt1 = "slide-module--quotetxt1--2ti6R";
export const svg = "slide-module--svg--1DTYd";
export const flexbox = "slide-module--flexbox--1lpfa";
export const flexl = "slide-module--flexl--3EGuz";
export const flexr = "slide-module--flexr--2xscM";
export const txttxt = "slide-module--txttxt--tSwGk";
export const slidebg = "slide-module--slidebg--3E6P2";
export const shake = "slide-module--shake--LmijM";