export function track(cta, category, action, text){

	// console.log(cta, category, action, text)
	
	let url = document.location.href;

	if(typeof global.ga === 'function'){
		global.ga('send', 
		{
			hitType: 'event',
			eventCategory: 'page',
			eventAction: 'view',
			eventLabel: text
		});
	}

	document.body.dispatchEvent(new CustomEvent('oTracking.event', { detail: { 
		category: category,
		action: action,
		product: 'paid-post',
		app: 'infographic',
		url: url,
		domPathTokens: [
			{
				"data-o-event": "category:"+category+"|action:"+action,
				"data-trackable": text,
				"href": url,
				"text": text
			}
		]
	}, bubbles: true}));

}