import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide1.module.css"
import { withpath } from '../../utils/index.js'
import H1 from "./slide4h"

const txt = [
	"<span>“</span>The ability to finance companies privately without public listing, or de-listing in order to streamline and focus on longer term goals, makes the private markets world attractive within a diversified multi-asset portfolio.”",
	"Henry Lee, HSBC Global Head of Discretionary Portfolio Management."
]

const Slide4_4 = (props) => {

	return (
		<section className={ss.slide} data-id={"4_4"}>
			
			<div id={`slidebg4_4`} className={ss.slidebg} data-id={"4_4"} style={{backgroundImage: `url(${withpath(`/img/slide44.jpg`)})` }} />

			<div className={ss.h1}>
				<H1 />
			</div>

			<div data-swiper-parallax-scale="0.25"
				className={ss.quotetxt1} 
			>
				<div 
					className={ss.quotetxtblack}  
					dangerouslySetInnerHTML={{__html: txt[0] }} 
					data-swiper-parallax="0"
				/>
			</div>

			<div 
				className={ss.quoteautor} 
				dangerouslySetInnerHTML={{__html: txt[1] }} 
				data-swiper-parallax="-400"
			/>

		</section>
)}

export default Slide4_4