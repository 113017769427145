import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide2.module.css"

import H1 from "./slide2h"
import { withpath } from '../../utils/index.js'
const txt = [
	"Elon Musk is reportedly invested in 19 companies, and Jeff Bezos owns 15 via Amazon, as well as having three ways in which he invests in more than 40 other ventures;",
	"his VC company, Bezos Expeditions, has interests in 16, and his own personal investments number an additional five."
]

const Slide2_1 = (props) => {

	return (
		<section className={ss.slide} data-id={"2_1"}>
			<div id={`slidebg2_1`} className={ss.slidebg} data-id={"2_1"} style={{backgroundImage: `url(${withpath(`/img/slide21.jpg`)})` }} />
			<div className={ss.h1}>
				<H1 />
			</div>
			<div className={ss.txttxt} data-id={"2_1"}>
				<div className={ss.midtxt} dangerouslySetInnerHTML={{__html: txt[0] }} data-swiper-parallax="400"/>
				<div className={ss.txt2} data-swiper-parallax="-400" dangerouslySetInnerHTML={{__html: txt[1] }} />
			</div>
		</section>
)}

export default Slide2_1