import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide1.module.css"
// import { gsap } from "gsap/dist/gsap";
import H1 from "./slide1h"
import { withpath } from '../../utils/index.js'
import Svg from '../../assets/s1_1.svg';
import Svgm from '../../assets/s1_1m.svg';

const txt = [
	"Taxes, inflation, natural dilution of assets among children and partners, and poor investment decisions are some of the reasons for such losses, but most damaging to a family’s fortune can be a lack of foresight and stewardship for the future when it comes to succession planning."
]

const Slide1_1 = (p) => {

	return (
		<section className={ss.slide} data-id={"1_1"}>
			<div 
				id={`slidebg1_1`} 
			className={ss.slidebg} data-id={"1_1"} style={{backgroundImage: `url(${withpath(`/img/slide11.jpg`)})` }} />


			<div className={ss.h1}>
				<H1 />
			</div>
			<div className={ss.txttxt} data-id={"1_1"}>
				<div className={ss.svg} data-id={"1_1"}>
					<div className={ss.mob}>
						<Svgm />
					</div>
					<div className={ss.desc}>
						<Svg />
					</div>
				</div>
				<div 
					className={ss.simpletxt} 
					data-id={"1_1"} 
					dangerouslySetInnerHTML={{__html: txt[0] }} 
					data-swiper-parallax="-400"
				/>
			</div>
		</section>
)}

export default Slide1_1