import React, { useEffect } from 'react'
import * as s from "./main.module.css"
// import useSize from '../utils/useSize';
// import useWindowWidth from '../utils/useWindowWidth';
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import Slider from "./slider"
import Arrow from '../assets/arrow.svg';


let size

const Main = (p) => {

	size = `SM`

	gsap.registerPlugin(ScrollTrigger);
	gsap.core.globals("ScrollTrigger", ScrollTrigger);
	gsap.config({
		autoSleep: 60,
		force3D: false,
		nullTargetWarn: false
	});

	useEffect(() => {
		let arrow = document.querySelector(`#mainarrowsvg`);
		gsap.killTweensOf([arrow]);
		gsap.to(arrow, {y: 20, duration: 2, ease: `power2.out`, yoyo: true, repeat: -1 });

		const MP10 = `#slidebg1_0`
		const ST10 = {
		  trigger: MP10,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl10 = gsap.timeline({scrollTrigger: ST10});
		tl10.set(MP10, {scale: 1.3, transformOrigin: "50% 50%"});
		tl10.to(MP10, {scale: 1});

		const MP11 = `#slidebg1_1`
		const ST11 = {
		  trigger: MP11,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl11 = gsap.timeline({scrollTrigger: ST11});
		tl11.set(MP11, {scale: 1.3, transformOrigin: "50% 50%"});
		tl11.to(MP11, {scale: 1});

		const MP12 = `#slidebg1_2`
		const ST12 = {
		  trigger: MP12,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl12 = gsap.timeline({scrollTrigger: ST12});
		tl12.set(MP12, {scale: 1.3, transformOrigin: "50% 50%"});
		tl12.to(MP12, {scale: 1});

		const MP14 = `#slidebg1_4`
		const ST14 = {
		  trigger: MP14,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl14 = gsap.timeline({scrollTrigger: ST14});
		tl14.set(MP14, {scale: 1.3, transformOrigin: "50% 50%"});
		tl14.to(MP14, {scale: 1});




		const MP20 = `#slidebg2_0`
		const ST20 = {
		  trigger: MP20,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl20 = gsap.timeline({scrollTrigger: ST20});
		tl20.set(MP20, {scale: 1.3, transformOrigin: "50% 50%"});
		tl20.to(MP20, {scale: 1});

		const MP21 = `#slidebg2_1`
		const ST21 = {
		  trigger: MP21,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl21 = gsap.timeline({scrollTrigger: ST21});
		tl21.set(MP21, {scale: 1.3, transformOrigin: "50% 50%"});
		tl21.to(MP21, {scale: 1});

		const MP22 = `#slidebg2_2`
		const ST22 = {
		  trigger: MP22,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl22 = gsap.timeline({scrollTrigger: ST22});
		tl22.set(MP22, {scale: 1.3, transformOrigin: "50% 50%"});
		tl22.to(MP22, {scale: 1});

		const MP24 = `#slidebg2_4`
		const ST24 = {
		  trigger: MP24,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl24 = gsap.timeline({scrollTrigger: ST24});
		tl24.set(MP24, {scale: 1.3, transformOrigin: "50% 50%"});
		tl24.to(MP24, {scale: 1});


		const MP30 = `#slidebg3_0`
		const ST30 = {
		  trigger: MP30,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl30 = gsap.timeline({scrollTrigger: ST30});
		tl30.set(MP30, {scale: 1.3, transformOrigin: "50% 50%"});
		tl30.to(MP30, {scale: 1});

		const MP31 = `#slidebg3_1`
		const ST31 = {
		  trigger: MP31,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl31 = gsap.timeline({scrollTrigger: ST31});
		tl31.set(MP31, {scale: 1.3, transformOrigin: "50% 50%"});
		tl31.to(MP31, {scale: 1});

		const MP32 = `#slidebg3_2`
		const ST32 = {
		  trigger: MP32,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl32 = gsap.timeline({scrollTrigger: ST32});
		tl32.set(MP32, {scale: 1.3, transformOrigin: "50% 50%"});
		tl32.to(MP32, {scale: 1});

		const MP34 = `#slidebg3_4`
		const ST34 = {
		  trigger: MP34,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl34 = gsap.timeline({scrollTrigger: ST34});
		tl34.set(MP34, {scale: 1.3, transformOrigin: "50% 50%"});
		tl34.to(MP34, {scale: 1});





		const MP40 = `#slidebg4_0`
		const ST40 = {
		  trigger: MP40,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl40 = gsap.timeline({scrollTrigger: ST40});
		tl40.set(MP40, {scale: 1.3, transformOrigin: "50% 50%"});
		tl40.to(MP40, {scale: 1});

		const MP41 = `#slidebg4_1`
		const ST41 = {
		  trigger: MP41,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl41 = gsap.timeline({scrollTrigger: ST41});
		tl41.set(MP41, {scale: 1.3, transformOrigin: "50% 50%"});
		tl41.to(MP41, {scale: 1});

		const MP41m = `#slidebg4_1m`
		const ST41m = {
		  trigger: MP41m,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl41m = gsap.timeline({scrollTrigger: ST41m});
		tl41m.set(MP41m, {scale: 1.3, transformOrigin: "50% 50%"});
		tl41m.to(MP41m, {scale: 1});




		const MP42 = `#slidebg4_2`
		const ST42 = {
		  trigger: MP42,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl42 = gsap.timeline({scrollTrigger: ST42});
		tl42.set(MP42, {scale: 1.3, transformOrigin: "50% 50%"});
		tl42.to(MP42, {scale: 1});

		const MP43 = `#slidebg4_3`
		const ST43 = {
		  trigger: MP43,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl43 = gsap.timeline({scrollTrigger: ST43});
		tl43.set(MP43, {scale: 1.3, transformOrigin: "50% 50%"});
		tl43.to(MP43, {scale: 1});

		const MP44 = `#slidebg4_4`
		const ST44 = {
		  trigger: MP44,
		  start: "top bottom",
		  end: "bottom bottom",
		  scrub: 0,
		  markers: false
		}
		let tl44 = gsap.timeline({scrollTrigger: ST44});
		tl44.set(MP44, {scale: 1.3, transformOrigin: "50% 50%"});
		tl44.to(MP44, {scale: 1});





	},[])

	return (
		<section className={s.main}>
			{
				p.data.slides.map((d,i)=>(
					<Slider dataid={i} data={d} key={i} size={size}/>
				))
			}
			<div className={s.padding} />
			<div className={s.arrow} id={`mainarrow`}>
				<Arrow />
			</div>
		</section>
)}

export default Main