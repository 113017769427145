import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide1.module.css"
import { Waypoint } from 'react-waypoint';
// import { gsap } from "gsap/dist/gsap";
import H1 from "./slide1h"
import { withpath } from '../../utils/index.js'
const txt = [
	"Business is usually personal and, when it comes to leaving a mark, there is a focus on legacies that extend beyond wealth transition to include the creation of a sustained reputation and sustainable impact. A growing demographic of elite Baby Boomers have high expectations of their millennial heirs, who collectively stand to inherit",
	"$30tn",
	"in the US alone over the next 20&nbsp;years. How can you give back without giving it all away?"
]


const Slide1_0 = (p) => {

	// const active = (p.slide === 0)

	// useEffect(() => {
	// 		// let t1 = document.querySelector(`.` + ss.simpletxt + `[data-id="1_0"]`);
	// 		// gsap.killTweensOf([t1]);
	// 		// gsap.set(t1, {opacity: 0})

	// 		// let t2 = document.querySelector(`.` + ss.conturtxt + `[data-id="1_0"]`);
	// 		// gsap.killTweensOf([t2]);
	// 		// gsap.set(t2, {opacity: 0})

	// 		// let t3 = document.querySelector(`.` + ss.whiteblacktxt + `[data-id="1_0"]`);
	// 		// gsap.killTweensOf([t3]);
	// 		// gsap.set(t3, {opacity: 0})
	// },[])


	function enter(n){
		// if(n===1){
		// 	let t1 = document.querySelector(`.` + ss.simpletxt + `[data-id="1_0"]`);
		// 	gsap.killTweensOf([t1]);
		// 	gsap.set(t1, {opacity: 0})
		// 	gsap.to(t1, {opacity: 1, duration: 1, ease: `power1.out` });
		// }
		// if(n===2){
		// 	let t1 = document.querySelector(`.` + ss.conturtxt + `[data-id="1_0"]`);
		// 	gsap.killTweensOf([t1]);
		// 	gsap.set(t1, {opacity: 0})
		// 	gsap.to(t1, {opacity: 1, duration: 1, ease: `power1.out` });
		// }
		// if(n===3){
		// 	let t1 = document.querySelector(`.` + ss.whiteblacktxt + `[data-id="1_0"]`);
		// 	gsap.killTweensOf([t1]);
		// 	gsap.set(t1, {opacity: 0})
		// 	gsap.to(t1, {opacity: 1, duration: 1, ease: `power1.out` });
		// }

	}
	function leave(n){

		// if(n===1){
		// 	let t1 = document.querySelector(`.` + ss.simpletxt + `[data-id="1_0"]`);
		// 	gsap.killTweensOf([t1]);
		// 	gsap.to(t1, {opacity: 0, duration: 0.25, ease: `power1.out` });
		// }
		// if(n===2){
		// 	let t1 = document.querySelector(`.` + ss.conturtxt + `[data-id="1_0"]`);
		// 	gsap.killTweensOf([t1]);
		// 	gsap.to(t1, {opacity: 0, duration: 0.25, ease: `power1.out` });
		// }
		// if(n===3){
		// 	let t1 = document.querySelector(`.` + ss.whiteblacktxt + `[data-id="1_0"]`);
		// 	gsap.killTweensOf([t1]);
		// 	gsap.to(t1, {opacity: 0, duration: 0.25, ease: `power1.out` });
		// }

	}

	return (
		<section className={ss.slide} data-id={"1_0"}>

			<div
				id={`slidebg1_0`} 
				className={ss.slidebg} 
				data-id={"1_0"} 
				style={{backgroundImage: `url(${withpath(`/img/slide10.jpg`)})` }} 
			/>


			<div className={ss.h1}>
				<H1 />
			</div>
			<div className={ss.txttxt} data-id={"1_0"}>
				
				<Waypoint 
					onEnter={(p) => {enter(1)}} 
					onLeave={(p) => {leave(1)}} 
					bottomOffset={"20%"} 
					scrollableAncestor={`window`} 
					fireOnRapidScroll={false}
				>
				<div 
					className={ss.simpletxt}
					data-id={"1_0"}
					dangerouslySetInnerHTML={{__html: txt[0] }}
					data-swiper-parallax="400"
				/>
				</Waypoint>

				<Waypoint 
					onEnter={(p) => {enter(2)}} 
					onLeave={(p) => {leave(2)}} 
					bottomOffset={"20%"} 
					scrollableAncestor={`window`} 
					fireOnRapidScroll={false}
				>
				<div 
					className={ss.conturtxt}
					data-id={"1_0"}
					data-swiper-parallax-scale="0.25"
				>
					<span 
						className={ss.conturspan10}
						dangerouslySetInnerHTML={{__html: txt[1] }} />
				</div>
				</Waypoint>

				<Waypoint 
					onEnter={(p) => {enter(3)}} 
					onLeave={(p) => {leave(3)}} 
					bottomOffset={"20%"} 
					scrollableAncestor={`window`} 
					fireOnRapidScroll={false}
				>
				<div 
					className={ss.whiteblacktxt}
					data-id={"1_0"}
					dangerouslySetInnerHTML={{__html: txt[2] }}
					data-swiper-parallax="-400"
				/>
				</Waypoint>
			</div>

		</section>
)}

export default Slide1_0