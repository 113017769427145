import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide1.module.css"

// import { gsap } from "gsap/dist/gsap";
import { withpath } from '../../utils/index.js'
import H1 from "./slide1h"
import Svg from '../../assets/s1_2.svg';
import Svgm from '../../assets/s1_2m.svg';

const txt = [
	"and the state in which the legacy leaver wants future generations to inherit it. Conscience and performance are no longer strangers; investing sustainably represents a higher share of portfolios and assets for multipreneurs and has become a must-have rather than just nice. Indeed a PWC study suggested that 79 per cent of family businesses in China are prioritising sustainable business practices versus 23 per cent in the US."
]



const Slide1_2 = (p) => {





	return (
		<section className={ss.slide} data-id={"1_2"}>
			<div 
id={`slidebg1_2`} 
			className={ss.slidebg} data-id={"1_2"} style={{backgroundImage: `url(${withpath(`/img/slide12.jpg`)})` }} />


			<div className={ss.h1}>
				<H1 />
			</div>
			<div className={ss.txttxt} data-id={"1_2"}>
				<div className={ss.svg} data-id={"1_2"}>
					<div className={ss.mob}>
						<Svgm />
					</div>
					<div className={ss.desc}>
						<Svg />
					</div>
				</div>
				<div 
					className={ss.simpletxt} 
					data-id={"1_2"} 
					dangerouslySetInnerHTML={{__html: txt[0] }} 
					data-swiper-parallax="-400"
				/>
			</div>
		</section>
)}

export default Slide1_2