import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide2.module.css"
import { withpath } from '../../utils/index.js'
// import { gsap } from "gsap/dist/gsap";
import H1 from "./slide2h"
import Svg from '../../assets/s2_3.svg';
import Svgm from '../../assets/s2_3m.svg';
const txt = [
	"This shows what can be done with the right support and insights, and it’s a good way to fund fledging businesses with potential or passion projects that can make a difference."
]

const Slide2_3 = (p) => {

	return (
		<section className={ss.slide} data-id={"2_3"}>
			<div id={`slidebg2_4`} className={ss.slidebg} data-id={"2_3"} style={{backgroundImage: `url(${withpath(`/img/slide23.jpg`)})` }} />
			<div className={ss.h1}>
				<H1 />
			</div>
			
			<div className={ss.txttxt} data-id={"2_3"}>
				<div 
					className={ss.simpletxt}
					data-id={"2_3"}
					dangerouslySetInnerHTML={{__html: txt[0] }} 
					data-swiper-parallax="400"
				/>

				<div className={ss.svg} data-id={"2_3"} data-swiper-parallax="0">
					<div className={ss.mob}>
						<Svgm />
					</div>
					<div className={ss.desc}>
						<Svg />
					</div>
				</div>
			</div>



		</section>
)}

export default Slide2_3