import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide3.module.css"
 
import H1 from "./slide3h"
import Svg from '../../assets/s3_1.svg';
import Svgm from '../../assets/s3_1m.svg';
import { withpath } from '../../utils/index.js'

const txt=[
	"<span>But where to go?</span> What drives the decision to pick a destination? Different countries vary in their capacity to provide a supportive entrepreneurial ecosystem, so becoming a global <span>business “resident”</span> means taking advantage of the diverse attitudes, resources and infrastructure of different countries and continents.",
	"on the global entrepreneurship index, all offering opportunities for a growing enterprise or established business set-up."
]

const Slide3_1 = (p) => {

	return (
		<section className={ss.slide} data-id={"3_1"}>
			
			<div id={`slidebg3_1`} className={ss.slidebg} data-id={"3_1"} style={{backgroundImage: `url(${withpath(`/img/slide31.jpg`)})` }} />

			<div className={ss.h1}>
				<H1 />
			</div>
<div className={ss.txttxt} data-id={"3_1"}>
			<div 
				className={ss.simpletxt} 
				data-id={"3_1"} 
				dangerouslySetInnerHTML={{__html: txt[0] }} 
				data-swiper-parallax="400"
			/>

			<div className={ss.svg} data-id={"3_1"} data-swiper-parallax="0">
					<div className={ss.mob}>
						<Svgm />
					</div>
					<div className={ss.desc}>
						<Svg />
					</div>
			</div>

			<div 
				className={ss.simpletxt} 
				data-id={"3_12"} 
				dangerouslySetInnerHTML={{__html: txt[1] }} 
				data-swiper-parallax="-400"
			/>
</div>
		</section>
)}

export default Slide3_1