// extracted by mini-css-extract-plugin
export const h = "slide0-module--h--3k7sZ";
export const h_0 = "slide0-module--h_0--sXxRX";
export const h_1 = "slide0-module--h_1--1OUXt";
export const h_line = "slide0-module--h_line--1F2D2";
export const startbox = "slide0-module--startbox--TNG3Z";
export const leftbox = "slide0-module--leftbox--1ZUOP";
export const h11 = "slide0-module--h11--2xUD8";
export const rightbox = "slide0-module--rightbox--1bifz";
export const txth = "slide0-module--txth--20Q80";
export const txttxt = "slide0-module--txttxt--3TVOy";
export const txtp = "slide0-module--txtp--3erfm";
export const shake = "slide0-module--shake--1KCSI";