import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide2.module.css"
// import { gsap } from "gsap/dist/gsap";
import H1 from "./slide2h"
import { withpath } from '../../utils/index.js'
const txt = [
	"Active in philanthropy and mentorship and a much-garlanded academic in the field of mechanical engineering,",
	" Ann Marie Sastry founded Amesite, a Michigan-based AI software firm focused on improving educational outcomes, in 2017; her previous startup, the innovative battery company Sakti3, was bought by Dyson in 2015",
	"for $90m."
]

const Slide2_2 = (p) => {

	return (
		<section className={ss.slide} data-id={"2_2"}>
			<div id={`slidebg2_2`} className={ss.slidebg} data-id={"2_2"} style={{backgroundImage: `url(${withpath(`/img/slide22.jpg`)})` }} />
			<div className={ss.h1}>
				<H1 />
			</div>
			
			<div className={ss.txttxt} data-id={"2_2"}>
				<div className={ss.txt} data-swiper-parallax="400">
					<span className={ss.txtblackbg2} dangerouslySetInnerHTML={{__html: txt[0] }} />
					<span className={ss.txtwhite} dangerouslySetInnerHTML={{__html: txt[1] }} />
				</div>
				<div 
					className={ss.conturtxt}
					data-swiper-parallax-scale="0.25"
					data-id={"2_2"}
				>
					<span id="as22" className={ss.conturtxt1span} dangerouslySetInnerHTML={{__html: txt[2] }} />
				</div>
			</div>
		</section>
)}

export default Slide2_2