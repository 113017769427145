import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide3.module.css"

import H1 from "./slide3h"
// import Svg from '../../assets/s3_3.svg';
import { withpath } from '../../utils/index.js'

const txt = [
	"<span>“</span>Many multipreneurs are seeking to establish&nbsp;and grow in multiple countries, ",
	"and&nbsp;are&nbsp;selecting a trusted partner with a global approach, who speak the language and&nbsp;truly understand the market.“",
	"Sidney Wang, HSBC’s Head of International Connectivity"
]

const Slide3_3 = (props) => {

	return (
		<section className={ss.slide} data-id={"3_3"}>

			<div id={`slidebg3_4`} className={ss.slidebg} data-id={"3_3"} style={{backgroundImage: `url(${withpath(`/img/slide33.jpg`)})` }} />

			<div className={ss.h1}>
				<H1 />
			</div>



			<div data-swiper-parallax-scale="0.25"
				className={ss.quotetxt} 
			>
				<div 
					className={ss.quotetxtred}  
					dangerouslySetInnerHTML={{__html: txt[0] }} 
					data-swiper-parallax="0"
				/>
				<div 
					className={ss.quotetxtblack}  
					dangerouslySetInnerHTML={{__html: txt[1] }} 
					data-swiper-parallax="0"
				/>
			</div>

			<div 
				className={ss.quoteautor} 
				dangerouslySetInnerHTML={{__html: txt[2] }} 
				data-swiper-parallax="-400"
			/>

		</section>
)}

export default Slide3_3