import React from 'react'
import * as s from "./slide3.module.css"

const txt = [
	"the hypermobile",
	"GLOBAL RESIDENT",
]

const Slide3h = (props) => {
	return (
		<div className={s.h}>
			<div className={s.h_0 + ` h0`} dangerouslySetInnerHTML={{__html: txt[0] }} />
			<div className={s.h_1 + ` h1`} dangerouslySetInnerHTML={{__html: txt[1] }} />
			<div className={s.h_line + ` hl`} />
		</div>
)}

export default Slide3h