import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide4.module.css"

import H1 from "./slide4h"
import Svg from '../../assets/s4_0.svg';
import Svgm from '../../assets/s4_0m.svg';
import { withpath } from '../../utils/index.js'
const txt = [
	"This is likely to represent a blend of risk, passion and practicality, with some high-performing cash-generating assets, as well as passion-led alternatives, and practical passive funds aimed at wealth protection. How can you strike the right balance?"
]

const Slide4_0 = (p) => {

	return (
		<section className={ss.slide} data-id={"4_0"}>

			<div id={`slidebg4_0`} className={ss.slidebg} data-id={"4_0"} style={{backgroundImage: `url(${withpath(`/img/slide40.jpg`)})` }} />

			<div className={ss.h1}>
				<H1 />
			</div>
<div className={ss.txttxt} data-id={"4_0"}>
			<div className={ss.svg} data-id={"4_0"}>
					<div className={ss.mob}>
						<Svgm />
					</div>
					<div className={ss.desc}>
						<Svg />
					</div>
			</div>

			<div 
				className={ss.simpletxt} 
				data-id={"4_0"} 
				dangerouslySetInnerHTML={{__html: txt[0] }} 
				data-swiper-parallax="-400"
			/>
</div>
		</section>
)}

export default Slide4_0