import React from 'react'
import * as ss from "./slide.module.css"
// import * as s from "./slide3.module.css"

import H1 from "./slide3h"
import Svg from '../../assets/s3_0.svg';
import { withpath } from '../../utils/index.js'
const txt = [
	"Borderless multipreneurs are true global citizens, living wherever they choose, with a greater ability than ever to work remotely and travel between multiple",
	"Roaming between continents can traditionally necessitate a substantial personal interest in property, and often results in ownership of more than three homes across the world."
]

const Slide3_0 = (p) => {

	return (
		<section className={ss.slide} data-id={"3_0"}>
			
			<div id={`slidebg3_0`} className={ss.slidebg} data-id={"3_0"} style={{backgroundImage: `url(${withpath(`/img/slide30.jpg`)})` }} />

			<div className={ss.h1}>
				<H1 />
			</div>


		<div className={ss.txttxt} data-id={"3_0"}>
			<div 
				className={ss.simpletxt} 
				data-id={"3_0"} 
				dangerouslySetInnerHTML={{__html: txt[0] }} 
				data-swiper-parallax="400"
			/>

			<div className={ss.svg} data-id={"3_0"}>
				<Svg />
			</div>

			<div 
				className={ss.simpletxt} 
				data-id={"3_02"} 
				dangerouslySetInnerHTML={{__html: txt[1] }} 
				data-swiper-parallax="-400"
			/>
		</div>
		</section>
)}

export default Slide3_0